import { quantityUpdated } from '../../../../analytics';
import { RetailItem, Variant } from '../../client';
import * as AlgoliaTypes from '../../routes/BrowsePage/AlgoliaTypes';

export type TrackQuantityUpdatedProps = {
  retailItem: Omit<any, 'logged_in' | 'url' | 'quantity'>;
  chosenQuantity: number;
  loggedIn: boolean;
};

export const mapRetailItemToQuantityUpdated = (
  retailItem: RetailItem,
  selectedVariant?: Variant,
): Omit<any, 'logged_in' | 'url' | 'quantity'> => {
  return {
    sku: selectedVariant ? selectedVariant.sku : retailItem.sku,
  };
};

export const mapHitToQuantityUpdated = (
  hit: AlgoliaTypes.Hit,
): Omit<any, 'logged_in' | 'url' | 'quantity'> => {
  return {
    sku: hit.sku,
  };
};

export const trackQuantityUpdated = ({
  retailItem,
  chosenQuantity,
  loggedIn,
}: TrackQuantityUpdatedProps) => {
  quantityUpdated({
    ...retailItem,
    logged_in: loggedIn,
    quantity: chosenQuantity,
  });
};
