import React from 'react';

import style from './index.module.css';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

type Props = {
  small?: boolean;
  value: number;
};

export const Price = ({ small, value }: Props) => {
  const className = `${style.price} ${small ? style.small : ''}`;

  return <span className={className}>{formatter.format(value)}</span>;
};
