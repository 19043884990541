import React from 'react';
import style from './index.module.css';

type Props = {
  data?: string;
};

const InnerHtml = ({ data }: Props) => (
  <div
    className={style.innerHtml}
    dangerouslySetInnerHTML={{
      __html: `${data}`,
    }}
  />
);

export default InnerHtml;
