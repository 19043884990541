import React from 'react';
import {
  Paragraph18Regular,
  Paragraph14Regular,
} from '@sothebys/sterling/typography';
import { Button } from '@sothebys/sterling/button';

import { Modal } from '../../atoms/modal';
import { Title } from '../../atoms';

import style from './index.module.css';

type Props = {
  showLoginModal: boolean;
  setShowLoginModal: (value: boolean) => void;
  login: () => void;
  register: () => void;
};

const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  login,
  register,
}: Props) => {
  return showLoginModal ? (
    <Modal closeModal={() => setShowLoginModal(false)} fullscreen={false}>
      <Title size="h4">Log in to proceed</Title>
      <Paragraph18Regular>
        To place this item on your bag please log in with your Sotheby’s
        account.
      </Paragraph18Regular>
      <div className={style.buttonsWrapper}>
        <Button buttonStyle="secondary" onClick={register}>
          <Paragraph14Regular>Create account</Paragraph14Regular>
        </Button>
        <Button onClick={login}>
          <Paragraph14Regular>Log in</Paragraph14Regular>
        </Button>
      </div>
    </Modal>
  ) : null;
};
export default LoginModal;
