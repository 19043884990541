import React from 'react';

import { Children } from '../types';
import style from './index.module.css';

type Props = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  size: 'h0' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
} & Children;

export const Title = ({ as, size, children }: Props) => {
  const sizeAsComponent = size === 'h0' ? 'h1' : size; // `h0` is not a valid HTML element.
  const Component = as || sizeAsComponent; // Default the component to its `size` if no `as` prop is given
  const className = style[size];

  return <Component className={className}>{children}</Component>;
};
