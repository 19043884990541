import { productAdded, ProductAdded } from '../../../../analytics';
import { RetailItem, Variant } from '../../client';
import TrackingStore from '../../stores/TrackingStore';
import {
  getCurrentPDPUrl,
  getRetailItemSeller,
  getSellerFromHit,
  isBrowser,
} from '../utils';
import { stringAfterSeparator } from '../strUtils';
import {
  getGeneralPeriodFromRetailItem,
  propertyTypeToContentType,
} from './shared';
import * as AlgoliaTypes from '../../routes/BrowsePage/AlgoliaTypes';

export type TrackProductAddedProps = {
  retailItem: Omit<ProductAdded, 'logged_in' | 'url' | 'quantity'>;
  chosenQuantity: number;
  host: string;
  path: string;
  loggedIn: boolean;
  user: any;
};

export const mapRetailItemToProductAdded = (
  retailItem: RetailItem,
  selectedVariant?: Variant,
): Omit<ProductAdded, 'logged_in' | 'url' | 'quantity'> => {
  const trackingState = TrackingStore.getState();
  const taggingList = retailItem.tags?.map((tag) => tag.tagLabel);
  const creatorsList = retailItem.creators?.map(
    (creator) => creator.displayName,
  );
  const originalImageUrl = retailItem.media?.images[0]?.renditions.find(
    (image) => image.imageSize === 'Original',
  );

  let hasVariants = retailItem?.variantGroup?.variants?.length > 1;

  return {
    product_id: selectedVariant
      ? selectedVariant.variantId
      : retailItem.retailItemId,
    sku: selectedVariant ? selectedVariant.sku : retailItem.sku,
    type: propertyTypeToContentType(retailItem.propertyType),
    content_type: propertyTypeToContentType(retailItem.propertyType),
    category: retailItem.category?.[0]?.name,
    category_2: retailItem.category?.[1]?.name,
    product_type: retailItem.objects?.[0]?.objectTypeName,
    department_category: retailItem.department?.title || '',
    name: retailItem.title,
    creator: creatorsList?.join(', '),
    price: selectedVariant
      ? selectedVariant.listPrice
      : retailItem.pricing.listPrice,
    currency: retailItem.pricing.currency,
    image_url: originalImageUrl?.url,
    queryID: trackingState.algoliaQueryID,
    objectID: trackingState.algoliaObjectID,
    index: trackingState.algoliaIndexName,
    lot_viking_id: retailItem.retailItemId,
    ecomm_prodid: [selectedVariant ? selectedVariant.sku : retailItem.sku],
    ecomm_totalvalue: selectedVariant
      ? selectedVariant.listPrice
      : retailItem.pricing.listPrice,
    ecomm_pagetype: 'cart',
    tags: taggingList?.join(' | '),
    period_general: getGeneralPeriodFromRetailItem(retailItem) || undefined,
    ships_internationally: retailItem.shipping?.shipsInternationally,
    variant:
      hasVariants && selectedVariant
        ? `${selectedVariant?.groupName}: ${selectedVariant?.value}`
        : '',
    seller: getRetailItemSeller(retailItem),
  };
};

export const mapHitToProductAdded = (
  hit: AlgoliaTypes.Hit,
): Omit<ProductAdded, 'logged_in' | 'url' | 'quantity'> => {
  const trackingState = TrackingStore.getState();

  return {
    product_id: hit.objectID,
    sku: hit.sku,
    content_type: hit.salesChannel,
    name: hit.title,
    category: hit.categories?.lvl0?.join(', '),
    category_2: hit.categories?.lvl1?.join(', '),
    product_type: hit.objectTypes?.filter((type) => type !== 'All')[0],
    department_category: hit.department,
    creator: hit.creators?.join(', '),
    price: hit.listPrice,
    currency: hit.currency,
    image_url: hit.imageUrl,
    tags: hit._tags?.join(' | '),
    ships_internationally: false,
    queryID: trackingState.algoliaQueryID,
    objectID: trackingState.algoliaObjectID,
    index: trackingState.algoliaIndexName,
    ecomm_pagetype: 'cart',
    ecomm_prodid: [hit.sku],
    ecomm_totalvalue: hit.listPrice,
    lot_viking_id: hit.objectID,
    type: 'Buy Now',
    seller: getSellerFromHit(hit),
  };
};

export const trackProductAdded = ({
  retailItem,
  chosenQuantity,
  host,
  path,
  loggedIn,
  user,
}: TrackProductAddedProps) => {
  if (isBrowser()) {
    productAdded({
      ...retailItem,
      url: getCurrentPDPUrl({ host, path }),
      logged_in: loggedIn,
      quantity: chosenQuantity,
      userToken: user ? stringAfterSeparator(user.sub, '|') : undefined,
      user_service_id: user?.vikingId,
    });
  }
};
