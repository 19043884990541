import * as React from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import style from './index.module.css';
import _ from 'lodash';

export interface CustomNextLinkProps extends LinkProps {
  children?: React.ReactNode;
  append?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  tabIndex?: number;
  id?: string;
  routeOutsideApp?: boolean;
}

const Link: React.FunctionComponent<CustomNextLinkProps> = ({
  children,
  append,
  as,
  tabIndex,
  id,
  routeOutsideApp,
  ...props
}) => {
  const router = useRouter();

  let nextAs;

  if (append) {
    const trimmedPath = _.trimEnd(router.asPath, '/');
    nextAs = `${trimmedPath}/${as}`;
  } else {
    nextAs = as;
  }

  if (routeOutsideApp) {
    return (
      <a id={id} href={props.href?.toString()} className={style.linkStyles}>
        {children}
      </a>
    );
  }
  return (
    <NextLink as={nextAs} {...props}>
      <a
        tabIndex={tabIndex}
        className={style.linkStyles}
        onClick={props.onClick}
        id={id}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
