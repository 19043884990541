import * as React from 'react';

import styles from './index.module.css';
import appConfig, { getOptionalEnvVariableWithDefault } from '../config';

import { isBrowser } from '../utils/utils';

let featureFlagsEnabled =
  getOptionalEnvVariableWithDefault('TIER', 'prod') !== 'prod';

type feature = {
  condition: (queryFromServerSide: string | undefined) => boolean;
  name: string;
  description: string;
  key: string;
  enabledInProd?: boolean;
};

// Used for feature flags that we want to serverside render
let parseQueryString = (
  maybeQueryStringServerSide: string | undefined,
): URLSearchParams => {
  let queryString = '';
  if (isBrowser()) {
    queryString = window.location.search;
  } else if (maybeQueryStringServerSide) {
    queryString = maybeQueryStringServerSide;
  }
  return new URLSearchParams(queryString);
};

export const PriceFilterEnabled = (): boolean => {
  if (isBrowser()) {
    const allQueryParams = new URLSearchParams(window.location.search);
    const fromUrl = allQueryParams.get('pricefilter');
    if (featureFlagsEnabled && fromUrl && fromUrl === 'true') {
      return true;
    }
  }
  return false;
};

export const ThreeSixtyImageEnabled = (): boolean => {
  if (isBrowser()) {
    const allQueryParams = new URLSearchParams(window.location.search);
    const fromUrl = allQueryParams.get('threesixtypdpimage');
    if (featureFlagsEnabled && fromUrl && fromUrl === 'true') {
      return true;
    }
  }
  return false;
};

export const ShowLotRecommendationFeatureEnabled = (): boolean => {
  if (isBrowser()) {
    const allQueryParams = new URLSearchParams(window.location.search);
    const fromUrl = allQueryParams.get('lotInRecommendations');
    if (featureFlagsEnabled && fromUrl && fromUrl === 'true') {
      return true;
    }
  }
  return false;
};

export const features: feature[] = [
  {
    condition: PriceFilterEnabled,
    name: 'Price filter logic',
    description:
      'Changes the price filter functionality to function the same as bidclient',
    key: 'pricefilter',
  },
  {
    condition: ThreeSixtyImageEnabled,
    name: '360 degree image in PDP',
    description: 'Shows 360 Image in PDP',
    key: 'threesixtypdpimage',
  },
  {
    condition: ShowLotRecommendationFeatureEnabled,
    name: 'Also show Lot in Recommendations',
    description: 'Also shows lot items in Recommendations carousel in PDP',
    key: 'lotInRecommendations',
  },
];

export const FeatureFlagMenu = () => {
  const [visible, setVisibility] = React.useState(() => false);
  const keyHandler = (event: any) => {
    if (event.ctrlKey && event.shiftKey && event.key === 'K') {
      setVisibility((current) => !current);
    }
  };
  React.useEffect(() => {
    featureFlagsEnabled = appConfig.tier() !== 'prod';
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, []);
  if (featureFlagsEnabled && visible) {
    return (
      <div className={styles.container}>
        {features.map((feature) => {
          return (
            <div
              className={`${styles.feature} ${
                feature.condition(undefined) ? styles.featureEnabled : ''
              }`}
              key={feature.name}
            >
              <div className={styles.featureName}>{feature.name}</div>
              <div>
                Query param: <code>{feature.key}</code>
              </div>
              <div>Description: {feature.description}</div>
              <div
                className={
                  feature.condition(undefined)
                    ? styles.enabled
                    : styles.disabled
                }
              >
                Status: {feature.condition(undefined) ? 'Enabled' : 'Disabled'}
              </div>
              {feature.enabledInProd && (
                <div className={styles.visibleInProd}>
                  This feature can be view in prod
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
