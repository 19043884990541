import React from 'react';
import { Button } from '@sothebys/sterling/button';
import {
  Label14Medium,
  Paragraph16Regular,
} from '@sothebys/sterling/typography';
import { Flags48Rounded } from '@sothebys/sterling/icons';
import { Modal as SterlingModal } from '@sothebys/sterling/modal';

import { Currency } from '../../client';
import { useCurrency } from '../../hooks/CurrencyHook';
import { trackSelectedCurrency } from '../../utils/segmentTracking';

import style from './index.module.css';

type CurrencyInformation = {
  currency: Currency;
  name: string;
  primaryImg: JSX.Element;
};

const currencyInformation: CurrencyInformation[] = [
  {
    currency: Currency.AED,
    name: 'United Arab Emirates dirham',
    primaryImg: <Flags48Rounded.Ae48Rounded />,
  },
  {
    currency: Currency.ARS,
    name: 'Argentine Peso',
    primaryImg: <Flags48Rounded.Ar48Rounded />,
  },
  {
    currency: Currency.AUD,
    name: 'Australian Dollar',
    primaryImg: <Flags48Rounded.Au48Rounded />,
  },
  {
    currency: Currency.BRL,
    name: 'Brazilian Real',
    primaryImg: <Flags48Rounded.Br48Rounded />,
  },
  {
    currency: Currency.CAD,
    name: 'Canadian Dollar',
    primaryImg: <Flags48Rounded.Ca48Rounded />,
  },
  {
    currency: Currency.CHF,
    name: 'Swiss Franc',
    primaryImg: <Flags48Rounded.Ch48Rounded />,
  },
  {
    currency: Currency.CNY,
    name: 'Chinese Yuan',
    primaryImg: <Flags48Rounded.Cn48Rounded />,
  },
  {
    currency: Currency.EUR,
    name: 'Euro',
    primaryImg: <Flags48Rounded.Eu48Rounded />,
  },
  {
    currency: Currency.GBP,
    name: 'British Pound',
    primaryImg: <Flags48Rounded.Gb48Rounded />,
  },
  {
    currency: Currency.HKD,
    name: 'Hong Kong Dollars',
    primaryImg: <Flags48Rounded.Hk48Rounded />,
  },
  {
    currency: Currency.IDR,
    name: 'Indonesian Rupiah',
    primaryImg: <Flags48Rounded.Id48Rounded />,
  },
  {
    currency: Currency.INR,
    name: 'Indian Rupee',
    primaryImg: <Flags48Rounded.In48Rounded />,
  },
  {
    currency: Currency.JPY,
    name: 'Japanese Yen',
    primaryImg: <Flags48Rounded.Jp48Rounded />,
  },
  {
    currency: Currency.KRW,
    name: 'South Korean Won',
    primaryImg: <Flags48Rounded.Kr48Rounded />,
  },
  {
    currency: Currency.MXN,
    name: 'Mexican Peso',
    primaryImg: <Flags48Rounded.Mx48Rounded />,
  },
  {
    currency: Currency.PHP,
    name: 'Philippine Peso',
    primaryImg: <Flags48Rounded.Ph48Rounded />,
  },
  {
    currency: Currency.QAR,
    name: 'Qatari Riyal',
    primaryImg: <Flags48Rounded.Qa48Rounded />,
  },
  {
    currency: Currency.RUB,
    name: 'Russian Ruble',
    primaryImg: <Flags48Rounded.Ru48Rounded />,
  },
  {
    currency: Currency.SGD,
    name: 'Singapore Dollar',
    primaryImg: <Flags48Rounded.Sg48Rounded />,
  },
  {
    currency: Currency.TWD,
    name: 'New Taiwan Dollar',
    primaryImg: <Flags48Rounded.Tw48Rounded />,
  },
  {
    currency: Currency.USD,
    name: 'United States Dollar',
    primaryImg: <Flags48Rounded.Us48Rounded />,
  },
];

type CurrencyConverterProps = {
  location: 'pdp' | 'browse';
  originalCurrency?: Currency;
};

const CurrencyConverter = (props: CurrencyConverterProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const currencyHook = useCurrency();
  const [currentSelectedCurrencyInfo, setCurrentSelectedCurrencyInfo] =
    React.useState<CurrencyInformation | null>(null);

  React.useEffect(() => {
    // When the hook value changes we need to re-render the new selected currency
    const currency = currencyInformation.find((info) => {
      return info.currency === currencyHook.selectedCurrency;
    });
    if (currency) {
      setCurrentSelectedCurrencyInfo(currency);
    }
  }, [currencyHook.selectedCurrency]);

  return (
    <>
      {currencyHook.allCurrencies && (
        <Button
          onClick={() => {
            setIsOpen((oldValue) => !oldValue);
          }}
          buttonStyle={'secondary'}
          size={'regular'}
          aria-label={`${currentSelectedCurrencyInfo?.name} currency chosen, select display currency...`}
          className={
            props.location === 'pdp'
              ? style.selectedCurrencyButtonPDP
              : style.selectedCurrencyButtonBrowse
          }
        >
          <div className={style.selectedCurrencyButtonContainer}>
            {currentSelectedCurrencyInfo?.primaryImg}
            <Label14Medium>{currencyHook.selectedCurrency}</Label14Medium>
          </div>
        </Button>
      )}
      <div>
        {isOpen && (
          <SterlingModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            headerTitle={'Select Currency'}
            size={'large'}
            fixHeader={true}
          >
            <SterlingModal.Content>
              <Paragraph16Regular className={style.modalDescription}>
                Display prices in another currency by selecting from the list
                below.
              </Paragraph16Regular>
              <ul>
                {currencyHook.allCurrencies && (
                  <div className={style.currencyRows}>
                    {currencyHook.allCurrencies?.rates.map((rate) => {
                      const currentCurrencyInfo = currencyInformation.find(
                        (info) => {
                          return info.currency === rate.currency;
                        },
                      );
                      if (currentCurrencyInfo) {
                        return (
                          <li key={rate.currency}>
                            <Button
                              buttonStyle={'tertiary'}
                              key={rate.currency}
                              onClick={() => {
                                currencyHook.setSeletectedRate(rate);
                                setIsOpen(false);
                                trackSelectedCurrency({
                                  originalCurrency: props.originalCurrency,
                                  selectedCurrency: rate.currency,
                                  currencySelectionOrigin:
                                    props.location === 'pdp'
                                      ? 'product page'
                                      : 'product list',
                                });
                              }}
                              className={style.currencyButton}
                              aria-label={`Select ${currentCurrencyInfo?.name} as display currency`}
                            >
                              <div className={style.informationContainer}>
                                <div className={style.imageAndCurrency}>
                                  <div className={style.flagContainer}>
                                    {currentCurrencyInfo?.primaryImg}
                                  </div>
                                  <Paragraph16Regular>
                                    {currentCurrencyInfo?.currency}
                                  </Paragraph16Regular>
                                </div>
                                <Paragraph16Regular
                                  className={style.currencyName}
                                >
                                  {currentCurrencyInfo?.name}
                                </Paragraph16Regular>
                              </div>
                            </Button>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                )}
              </ul>
              <SterlingModal.Footer>
                Note: Currency conversions are for guidance only; payment must
                be made in USD.
              </SterlingModal.Footer>
            </SterlingModal.Content>
          </SterlingModal>
        )}
      </div>
    </>
  );
};

export default CurrencyConverter;
