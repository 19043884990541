import { SubheadXL } from '@sothebys/sterling/typography';
import React, { useState } from 'react';

import SvgChevronDown from '../../assets/chevron_down.svg';
import SvgChevronUp from '../../assets/chevron_up.svg';
import style from './index.module.css';

type Props = {
  name: React.ReactNode;
  description?: React.ReactNode;
  open?: boolean;
  children: React.ReactNode;
};

export const Accordion = ({
  name,
  description,
  children,
  open: openDefault = false,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState(openDefault);

  return (
    <div className={style.accordion}>
      <div className={style.header} onClick={(): void => setOpen(!open)}>
        <SubheadXL className={style.title}>{name}</SubheadXL>
        <div className={style.description}>
          {description}
          {open ? (
            <img src={SvgChevronUp} alt="Up arrow" />
          ) : (
            <img src={SvgChevronDown} alt="Down arrow" />
          )}
        </div>
      </div>
      {open && <div className={style.body}>{children}</div>}
    </div>
  );
};
