import style from './index.module.css';
import { Children } from '../types';
import Close from '../../assets/close.svg';
import { useLockBodyScroll } from '../../utils/customHooks';
import { useEffect, useState, useRef } from 'react';
import { isServer } from '../../utils/utils';
import { useOnClickOutside } from '../../utils/customHooks';

type ModalProps = {
  closeModal: () => void;
  fullscreen?: boolean;
  triggerOfClosing?: {
    value: boolean;
    updateValue: (value: boolean) => void;
  };
  type?: 'Regular' | 'Large';
  scrollable?: boolean;
} & Children;

export const Modal = ({
  children,
  closeModal,
  fullscreen = true,
  triggerOfClosing,
  type = 'Regular',
  scrollable,
}: ModalProps) => {
  // Call hook to lock body scroll
  useLockBodyScroll();
  const [isVisible, setIsVisible] = useState(true);
  const fadeOutModal = () => {
    setIsVisible(false);
    setTimeout(closeModal, 250);
  };
  const handleEscapePress = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      fadeOutModal();
    }
  };
  useEffect(() => {
    if (triggerOfClosing?.value) {
      triggerOfClosing.updateValue(false);
      fadeOutModal();
    }
  }, [triggerOfClosing?.value]);
  // Add event listeners
  useEffect(() => {
    if (isServer()) {
      return undefined;
    }
    window.addEventListener('keydown', handleEscapePress);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const fullScreenModal = () => (
    <div
      className={`${style.modalWrapper} ${scrollable ? style.scrollable : ''} ${
        isVisible ? style.visible : ''
      }`}
    >
      {children}
      <button
        type="button"
        onClick={fadeOutModal}
        className={style.closeBtn}
        aria-label="Close"
      >
        <img src={Close} alt="Close icon" />
      </button>
    </div>
  );

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: modalRef,
    handler: () => {
      fadeOutModal();
    },
  });

  const modalWithBackdrop = () => (
    <div
      className={` ${style.modalWrapper} ${style.backdrop} ${
        scrollable ? style.scrollable : ''
      } ${isVisible ? style.visible : ''} `}
    >
      <div
        className={`${style.modalBody} ${type === 'Large' ? style.large : ''}`}
        ref={modalRef}
      >
        {children}
        <button
          type="button"
          onClick={fadeOutModal}
          className={style.closeBtn}
          aria-label="Close"
        >
          <img src={Close} alt="Close icon" />
        </button>
      </div>
    </div>
  );
  if (fullscreen) {
    return fullScreenModal();
  } else {
    return modalWithBackdrop();
  }
};
