import { isBrowser } from './utils';

declare global {
  interface Window {
    updateBagLinkCount: () => void;
    consentManager: any;
  }
}

export const GUEST_CART_BAGID_KEY = 'MARKETPLACE_GUEST_BAG_ID';

export const GUEST_ALCOHOL_CART_BAGID_KEY = 'MARKETPLACE_ALCOHOL_GUEST_BAG_ID';

export const getGuestBagIdKey = (isAlcoholBag: boolean) =>
  isAlcoholBag ? GUEST_ALCOHOL_CART_BAGID_KEY : GUEST_CART_BAGID_KEY;

export const updateCart = (): void => {
  if (isBrowser()) {
    const actualFunction = window?.updateBagLinkCount;
    const updateFunction = () => {
      try {
        actualFunction();
      } catch (error) {}
    };
    const doesUpdateFunctionExist = () => {
      return actualFunction && typeof actualFunction === 'function';
    };
    if (doesUpdateFunctionExist()) {
      updateFunction();
    } else {
      setTimeout(() => {
        if (doesUpdateFunctionExist()) {
          updateFunction();
        }
      }, 2000);
    }
  }
};
