import { useEffect, useRef, RefObject } from 'react';
import { isBrowser, isServer, useIsomorphicLayoutEffect } from '../utils/utils';

export const useLockBodyScroll = () => {
  useIsomorphicLayoutEffect(() => {
    // Get original body overflow
    if (isServer()) {
      return undefined;
    }
    const originalPosition = window.getComputedStyle(document.body).position;
    const originalRight = window.getComputedStyle(document.body).right;
    const originalLeft = window.getComputedStyle(document.body).left;
    const originalTop = window.getComputedStyle(document.body).top;
    const originalMarginRight = window.getComputedStyle(
      document.body,
    ).marginRight;
    const originalMarginLeft = window.getComputedStyle(
      document.body,
    ).marginLeft;
    const originalOverflowY = window.getComputedStyle(document.body).overflowY;
    // Prevent scrolling on mount
    const topPos = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.right = '0';
    document.body.style.left = '0';
    document.body.style.top = `-${topPos}px`;
    document.body.style.marginRight = 'auto';
    document.body.style.marginLeft = 'auto';
    document.body.style.overflowY = 'scroll';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.position = originalPosition;
      document.body.style.right = originalRight;
      document.body.style.left = originalLeft;
      document.body.style.top = originalTop;
      document.body.style.marginRight = originalMarginRight;
      document.body.style.marginLeft = originalMarginLeft;
      document.body.style.overflowY = originalOverflowY;
      window.scrollTo({ top: topPos });
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export const usePrevious = (value: any) => {
  //https://usehooks.com/usePrevious/
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
};

type UseOnClickOutsideProps = {
  ref: RefObject<any>;
  handler: () => void;
};

export const useOnClickOutside = ({ ref, handler }: UseOnClickOutsideProps) => {
  useEffect(() => {
    if (isServer()) {
      return undefined;
    }
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (ref.current && event.target && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', (e: MouseEvent) => {
      listener(e);
    });
    document.addEventListener('touchstart', (e: TouchEvent) => {
      listener(e);
    });

    return () => {
      document.removeEventListener('mousedown', (e: MouseEvent) => {
        listener(e);
      });
      document.removeEventListener('touchstart', (e: TouchEvent) => {
        listener(e);
      });
    };
  }, [ref, handler]);
};
