import React from 'react';

import { Children } from '../types';
import style from './index.module.css';

type Props = {
  book?: boolean;
  bold?: boolean;
  small?: boolean;
  medium?: boolean;
  semibold?: boolean;
} & Children;

export const Label = ({
  book,
  bold,
  small,
  medium,
  semibold,
  children,
}: Props) => (
  <span
    className={`${style.label} ${book ? style.book : ''} ${
      bold ? style.bold : ''
    } ${small ? style.small : ''} ${medium ? style.medium : ''}
    ${semibold ? style.semibold : ''}`}
  >
    {children}
  </span>
);
