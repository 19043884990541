import React from 'react';
import { useCurrency } from '../../hooks/CurrencyHook';
import { Tooltip } from '@sothebys/sterling/tooltip';
import { Currency } from './../../client';
import { amountToString } from '../../utils/strUtils';

import style from './index.module.css';

type CurrencyConverterProps = {
  listPrice: number;
  currency: Currency;
  toFloat?: boolean;
  renderAmount: (amount: string) => React.ReactElement;
  renderTooltip: (amount: string) => React.ReactElement;
};

const CurrencyAmount = ({
  currency,
  listPrice,
  toFloat = false,
  renderAmount,
  renderTooltip,
}: CurrencyConverterProps) => {
  const currencyHook = useCurrency();

  return (
    <Tooltip
      title={renderTooltip(
        amountToString(listPrice, currency || 'N/A', toFloat),
      )}
      tooltipStyle={'primary'}
      className={style.tooltip}
    >
      {renderAmount(
        currencyHook.convertedCurrency(listPrice, currency, toFloat),
      )}
    </Tooltip>
  );
};

export default CurrencyAmount;
