import * as React from 'react';
import style from './index.module.css';
import Link from '../../components/link';

export type HierarchyItem = {
  label: string;
  query: {
    [key: string]: string;
  };
  href: string;
  pathname: string;
  isLink: boolean;
};

type Props = {
  levels?: HierarchyItem[];
  isLastItemClickable?: boolean;
};

export const Hierarchy: React.FunctionComponent<Props> = ({
  levels: levels,
  isLastItemClickable = false,
}) => (
  <>
    {levels?.map((item, index) => {
      const isLastItem = levels.length > index + 1;
      const isClickable =
        levels.length !== index + (isLastItemClickable ? 0 : 1) && item.isLink;

      return (
        <span key={item.label + '-' + index} className={style.container}>
          {isClickable ? (
            <span className={style.link}>
              <Link
                href={{
                  pathname: item.pathname,
                  query: item.query,
                }}
                as={item.href}
              >
                {item.label}
              </Link>
            </span>
          ) : (
            <p className={style.activeItem}>{item.label}</p>
          )}
          {isLastItem && <p className={style.divider}>/</p>}
        </span>
      );
    })}
  </>
);
